import {createSlice} from "@reduxjs/toolkit";

const user = createSlice({
  name: 'user',
  initialState: {
    email: '',
  },
  reducers: {
    fetchData: (state, action) => {
      return {
        email: action.payload
      };
    },
    resetUser: (state) => {
      return {
        email: '',
      }
    }
  }
})
export const { fetchData, resetUser } = user.actions;

export default user.reducer
