import {
  configureStore,
  getDefaultMiddleware
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import saga from "../saga/saga";
import userReducer from "./user"
import notistackReducer from "./notistack";
import dictionaryReducer from "./dictionary";
import currentPageReducer from "./currentPage"
import filtersReducer from "./filters"

let sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

const store = configureStore({
  reducer: {
    user: userReducer,
    notistack: notistackReducer,
    dictionary: dictionaryReducer,
    currentPage: currentPageReducer,
    filters: filtersReducer,
  },
  middleware
})

sagaMiddleware.run(saga);

export default store;