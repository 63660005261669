import { put, takeEvery, call } from 'redux-saga/effects'
import {sagaUserActions} from "./sagaActions";
import {callAPI} from "../fuction";
import {fetchData} from "../store/user";

function* getMe() {
  try {
    const response = yield call(callAPI, {
      url: '/api/v1/me',
      method: 'GET',
      data: {}
    })
    const data = yield response;
    yield put(fetchData(data.data.email))
  } catch (e) {
    console.log('Błąd pobierania danych')
  }
}

function* userSaga() {
  yield takeEvery(sagaUserActions.GET_USER_DATA_SAGA, getMe)
}

export { userSaga };

