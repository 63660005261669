import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";
import {callAPI} from "../../fuction";
import {setNoticeError, setNoticeSuccess} from "../../store/notistack";
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  let params = useParams();
  const dispatch = useDispatch()
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData(e.target)

    const jsonData = Object.fromEntries(data.entries())

    callAPI({
      url: `/api/v1/users/reset-password/${params.hash}`,
      method: 'POST',
      data: jsonData
    }).then(res => {
      dispatch(setNoticeSuccess('Hasło zostało zresetowane. Zaloguj się na nowe dane.'))
      history.push("/login")
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 400) {
          dispatch(setNoticeError('Podałeś niepoprawne dane. Pole Hasło i Powtórz hasło muszą być takie same'))
        } else if (error.response.status === 404) {
          dispatch(setNoticeError('Błąd. Prawdopodbnie link aktywacyjny wygasł.'))
        }
      } else {
        dispatch(setNoticeError('Błąd. Spróbuj wykonać ponownie procedurę odzyskiwania hasła.'))
      }
    })

    return false
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Zresetuj hasło do systemu zamówień Wejkama
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            inputProps={{
              minlength: 8,
            }}
            name="password"
            label="Hasło"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            inputProps={{
              minlength: 8,
            }}
            name="repeated_password"
            label="Powtórz hasło"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Zresetuj hasło
          </Button>
        </form>
      </div>
    </Container>
  );
}