import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink } from 'react-router-dom';
import { callAPI } from "../../fuction";
import { useDispatch } from 'react-redux'
import {setNoticeError, setNoticeSuccess} from "../../store/notistack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch()
  let history = useHistory();

  const handleSubmitForm = (e) => {
    e.preventDefault()
    const form = e.target
    const data = new FormData(form)
    callAPI({
      url: '/oauth/v2/token',
      method: 'POST',
      data: {
        "grant_type": "password",
        "client_id": "1_421n6BgtWgpAr7tE6xWwxxc89KyZ8QnDup4ZQ27nMbaptvbuzy",
        "client_secret": "PQPtxWm8pATknb8aPs8wDqDPzH3n9Jj7SfSpo23LKsnRT874ER",
        "username": data.get('email'),
        "password": data.get('password')
      }
    })
      .then(resp => {
        sessionStorage.setItem('wejkamaAT', resp.data.access_token)
        dispatch(setNoticeSuccess('Zalogowano poprawnie'))
        history.push("/");
      })
      .catch(() => {
        dispatch(setNoticeError('Niewłaściwiy login lub hasło'))
    })
    return false;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Zaloguj się do systemu zamówień Wejkama
        </Typography>
        <form className={classes.form} onSubmit={handleSubmitForm}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adres e-mail"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Hasło"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Zaloguj się
          </Button>
          <Grid container>
            <Grid item xs>
              <RouterLink to="/forgot-passwrord" variant="body2" component={Link} >
                Zapomniałeś hasła?
              </RouterLink>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}