export const sagaUserActions = {
  GET_USER_DATA_SAGA: "GET_USER_DATA_SAGA",
};

export const sagaDictionaryActions = {
  GET_DICTIONARY_DATA_SAGA: 'GET_DICTIONARY_DATA_SAGA'
}

export const getDictionaryData = () => ({
  type: sagaDictionaryActions.GET_DICTIONARY_DATA_SAGA
})

export const getUserData = () => ({
  type: sagaUserActions.GET_USER_DATA_SAGA
})