import React, {useEffect, useState} from 'react';
import {callAPI, dateInfo, status, statusColor, statusList, statusVariant} from "../../fuction";
import {
  Paper,
  Typography,
  Container,
  TablePagination,
  Button,
  TableSortLabel,
  TextField,
  FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import { useHistory } from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {setName} from "../../store/currentPage";
import { setPage, setLimit, setDateFilter, setStatusFilter, setNameFilter, setNipFilter, setBrandFilter, setOrderCompletionMonth, setOrderCompletionTime, setOrderNumber, setDateOrder, setNameOrder, setCabinFilter, setClientAddress } from "../../store/filters";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    marginBottom: theme.spacing(8)
  },
  filter: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  sort: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: '10px'
  },
  headrow: {
    alignItems: 'flex-end'
  },
  input: {
    width: '100%',
  }
}));

const displayData = (data) => {
  const dataObject = new Date(data)
  return `${dataObject.getDate() < 10 ? '0' : ''}${dataObject.getDate()}-${dataObject.getMonth() < 9 ? '0' : ''}${dataObject.getMonth() + 1}-${dataObject.getFullYear()}`
}

export default function OrderList() {
  const classes = useStyles();
  const history = useHistory();
  const cabinTypes = useSelector(state => state.dictionary.cabins)
  const brands = useSelector(state => state.dictionary.brands)
  const dispatch = useDispatch()
  const filters = useSelector(state => state.filters)

  const [orders, setOrders] = useState([])
  const [all, setAll] = useState(0)

  useEffect(() => {
    dispatch(setName('Lista zamówień'))
  }, [dispatch])

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('page', String(filters.page + 1));
    params.append('limit', String(filters.limit));
    params.append('created_date_order', String(filters.dateOrder));
    params.append('client_name_order', String(filters.nameOrder));
    params.append('client_address', String(filters.clientAddress));
    params.append('order_status', filters.statusFilter);
    params.append('product_type', filters.cabinFilter);
    params.append('brand', filters.brandFilter);
    params.append('nip', filters.nipFilter);
    params.append('last_name', filters.nameFilter);
    params.append('order_date', filters.dateFilter);
    params.append('orderNumber', filters.orderNumber);
    params.append('orderCompletionTime', filters.orderCompletionTime);
    params.append('orderCompletionMonth', filters.orderCompletionMonth);
    callAPI({
      url: '/api/v1/search-orders-paginated',
      method: 'GET',
      data: {},
      params
    })
      .then(resp => {
        setOrders(resp.data)
        setAll(Number(resp.headers['x-total-count']))
      })
  }, [filters])

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  const createSortHanlder = (type) => {
    switch (type) {
      case 'number':
        dispatch(setDateOrder(0))
        dispatch(setNameOrder(0))
        break;
      case 'date':
        dispatch(setDateOrder(filters.dateOrder === 1 ? 2 : 1))
        dispatch(setNameOrder(0))
        break;
      case 'name':
        dispatch(setDateOrder(0))
        dispatch(setNameOrder(filters.nameOrder === 1 ? 2 : 1))
        break
      default:
        dispatch(setDateOrder(0))
        dispatch(setNameOrder(0))
    }
  }

  const handleChangeFilterStatus = (e) => {
    dispatch(setStatusFilter(String(e.target.value)))
  }
  const handleChangeFilterCabin = (e) => {
    dispatch(setCabinFilter(String(e.target.value)))
  }

  const handleChangeFilterBrand = (e) => {
    dispatch(setBrandFilter(String(e.target.value)))
  }
  const handleChangeFilterNip = (e) => {
    // if (e.target.value.length > 3) {
      dispatch(setNipFilter(String(e.target.value)))
    // } else if (e.target.value.length === 0) {
    //   dispatch(setNipFilter(''))
    // }
  }
  const handleChangeFilterNumber = (e) => {
    // if (e.target.value.length > 1) {
      dispatch(setOrderNumber(String(e.target.value)))
    // } else if (e.target.value.length === 0) {
    //   dispatch(setOrderNumber(''))
    // }
  }
  const handleChangeFilterName = (e) => {
    // if (e.target.value.length > 3) {
      dispatch(setNameFilter(String(e.target.value)))
    // } else if (e.target.value.length === 0) {
    //   dispatch(setNameFilter(''))
    // }
  }

  const handleChangeFilterAddress = (e) => {
    // if (e.target.value.length > 2) {
      dispatch(setClientAddress(String(e.target.value)))
    // } else if (e.target.value.length === 0) {
    //   dispatch(setClientAddress(''))
    // }
  }
  const handleChangeFilterDate = (e) => {
    // if (e.target.value.length > 3) {
      dispatch(setDateFilter(String(e.target.value)))
    // } else if (e.target.value.length === 0) {
    //   dispatch(setDateFilter(''))
    // }
  }
  const handleChangeFilterDateCompletion = (e) => {
    // if (e.target.value.length > 3) {
      dispatch(setOrderCompletionTime(String(e.target.value)))
    // } else if (e.target.value.length === 0) {
    //   dispatch(setOrderCompletionTime(''))
    // }
  }
  const handleChangeFilterDateCompletionMonth = (e) => {
    // if (e.target.value.length > 3) {
      dispatch(setOrderCompletionMonth(String(e.target.value)))
    // } else if (e.target.value.length === 0) {
    //   dispatch(setOrderCompletionMonth(''))
    // }
  }

  const CompareDate = ({date}) => {
    const order = new Date(date)
    const today = new Date()

    const compare = (order - today) / 1000 / 60 / 60 / 24

    if (compare < 0) {
      return (
        <>
          <Typography variant="body2" style={{color: 'red'}}>
            Opóźnienie {Math.ceil(compare)} dni
          </Typography>
        </>
      )
    } else if (compare >= 0 && compare <= 7) {
      return (
        <>
          <Typography variant="body2" style={{color: 'orange'}}>
            Do końca terminu realizacji pozostało {Math.ceil(compare)} dni
          </Typography>
        </>
      )
    }

    return (
      <></>
    )
  }

  return (
    <>
      <Container>
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h1" className={classes.title}>
            Lista zamówień
          </Typography>
          <Button color="primary" variant="contained" onClick={() => history.push("/new-order")}>
            Nowe zamówienie
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className={classes.filter}>
                    <TextField name="orderNumber"
                               onChange={handleChangeFilterNumber}
                               value={filters.orderNumber}
                               size="small"
                               label="Numer zamówienia"  />
                  </div>
                </TableCell>
                <TableCell >
                  <div className={classes.filter}>
                    <TextField name="order_date"
                               inputProps={{
                                 type: 'date'
                               }}
                               value={filters.dateFilter}
                               onChange={handleChangeFilterDate}
                               size="small"
                               InputLabelProps={{
                                 shrink: true,
                               }}
                               label="Data"  />
                  </div>
                </TableCell>
                <TableCell >
                  <div className={classes.filter}>
                    <TextField name="order_completion_time"
                               inputProps={{
                                 type: 'date'
                               }}
                               onChange={handleChangeFilterDateCompletion}
                               value={filters.orderCompletionTime}
                               size="small"
                               InputLabelProps={{
                                 shrink: true,
                               }}
                               disabled={false}
                               label="Termin realizacji"  />
                  </div>
                  <div className={classes.filter}>
                    <TextField name="order_completion_month"
                               inputProps={{
                                 type: 'date'
                               }}
                               onChange={handleChangeFilterDateCompletionMonth}
                               value={filters.orderCompletionMonth}
                               size="small"
                               InputLabelProps={{
                                 shrink: true,
                               }}
                               disabled={false}
                               label="Miesiąc realizacji"  />
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.filter}>
                    <TextField name="last_name"
                               size="small"
                               onChange={handleChangeFilterName}
                               value={filters.nameFilter}
                               label="Nazwa klienta"  />
                    <TextField name="nip"
                               size="small"
                               onChange={handleChangeFilterNip}
                               value={filters.nipFilter}
                               label="NIP klienta"  />
                    <TextField name="client_address"
                               size="small"
                               onChange={handleChangeFilterAddress}
                               value={filters.clientAddress}
                               label="Adress"  />
                  </div>
                </TableCell>
                <TableCell>
                  {cabinTypes !== undefined && cabinTypes.length > 0 && (
                    <div className={classes.filter}>
                    <FormControl className={classes.input}>
                      <InputLabel id="cabins-types">Typ kabiny</InputLabel>
                      <Select size="small"
                              name="cabin_id"
                              labelId="cabins-types"
                              onChange={handleChangeFilterCabin}
                              value={filters.cabinFilter}
                      >
                        <MenuItem value="">Wszystkie</MenuItem>
                        {cabinTypes.map(el => (
                          <MenuItem key={el.uuid} value={el.name}>{el.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    </div>
                  )}
                  {brands !== undefined && brands.length > 0 && (
                      <div className={classes.filter}>
                        <FormControl className={classes.input}>
                          <InputLabel id="brands">Marka s.</InputLabel>
                          <Select size="small"
                                  name="brand_id"
                                  labelId="brands"
                                  onChange={handleChangeFilterBrand}
                                  value={filters.brandFilter}
                          >
                            <MenuItem value="">Wszystkie</MenuItem>
                            {brands.map(el => (
                                <MenuItem key={el.uuid} value={el.name}>{el.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                  )}
                </TableCell>
                <TableCell>
                  <div className={classes.filter}>
                    <FormControl className={classes.input}>
                      <InputLabel id="status">Status</InputLabel>
                      <Select size="small"
                              name="status"
                              labelId="status"
                              onChange={handleChangeFilterStatus}
                              value={filters.statusFilter}
                      >
                        <MenuItem value="">Wszystkie</MenuItem>
                        {statusList.map(el => (
                          <MenuItem key={el} value={el}>{status(el)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={filters.dateOrder === 0 && filters.nameOrder === 0}
                    direction="asc"
                    onClick={() => createSortHanlder('number')}
                  >
                    Numer zamówienia
                  </TableSortLabel>
                </TableCell>
                <TableCell >
                    <TableSortLabel
                      className={classes.sort}
                      active={filters.dateOrder !== 0 && filters.nameOrder === 0}
                      direction={filters.dateOrder === 1 ? 'asc' : 'desc'}
                      onClick={() => createSortHanlder('date')}
                    >
                      Data zamówienia
                    </TableSortLabel>
                </TableCell>
                <TableCell>Termin realizacji zamówienia</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filters.dateOrder === 0 && filters.nameOrder !== 0}
                    direction={filters.nameOrder === 1 ? 'asc' : 'desc'}
                    onClick={() => createSortHanlder('name')}
                  >
                    Klient
                  </TableSortLabel>
                </TableCell>
                <TableCell>Typ produktu i marka pojazdu</TableCell>
                <TableCell>Status i etapy realizacji</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((row) => (
                <TableRow key={row.uuid}>
                  <TableCell>
                    <Button onClick={() => history.push(`/detail/${row.uuid}`, {order: row})}>
                      {row.order_number}
                    </Button>
                    {row.manual_order_number && (
                      <Button onClick={() => history.push(`/detail/${row.uuid}`, {order: row})}>
                        <strong>{row.manual_order_number}</strong>
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{displayData(row.created_at)}</TableCell>
                  <TableCell>
                    <span>
                      {dateInfo(row.order_completion_time, false)}
                    </span>
                    {(row.status === 1 || row.status === 0) && row.order_completion_time !== null && (
                      <CompareDate date={row.order_completion_time} />
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">{row.client_name}</Typography>
                    <Typography variant="body2" color="textSecondary">{row.client_nip}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">{row.cabin.name}</Typography>
                    <Typography variant="body2" color="textSecondary">{row.brand.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      variant="default"
                      style={{
                        background: statusVariant(row.status),
                        color: statusColor(row.status)
                      }}
                      label={status(row.status)}
                      size="small"
                    />
                    <Typography variant="body2" color="textSecondary" style={{
                      fontSize: '10px',
                      marginTop: '10px'
                    }}>
                      {row.laminated && (<>Wylaminowana, </>)}
                      {row.outcropped && (<>Wykostkowana, </>)}
                      {row.upholstered && (<>Wytapicerowana, </>)}
                      {row.varnishing && (<>Lakierowanie, </>)}
                      {row.ready_to_assembly && (<>Gotowa do montażu, </>)}
                      {row.assembly && (<>Montaż, </>)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[1, 5, 10, 25]}
            component="div"
            count={all}
            rowsPerPage={filters.limit}
            page={filters.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Liczba wierszy na stronę:"
          />
        </Paper>
      </Container>
      </>
  )
}