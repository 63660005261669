import {createSlice} from "@reduxjs/toolkit";

const notistackSlice = createSlice({
  name: 'notistack',
  initialState: {
    type: '', // variant could be success, error, warning, info, or default
    text: '',
  },
  reducers: {
    setNoticeSuccess: (state, action) => {
      return {
        text: action.payload,
        type: 'success'
      };
    },
    setNoticeError: (state, action) => {
      return {
        text: action.payload,
        type: 'error'
      };
    },
    setNoticeWarning: (state, action) => {
      return {
        text: action.payload,
        type: 'warning'
      };
    },
    setNoticeInfo: (state, action) => {
      return {
        text: action.payload,
        type: 'info'
      };
    }
  }
})
export const { setNoticeSuccess, setNoticeError, setNoticeWarning, setNoticeInfo } = notistackSlice.actions;
export default notistackSlice.reducer
