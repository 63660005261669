import { put, takeEvery, call } from 'redux-saga/effects'
import {sagaDictionaryActions} from "./sagaActions";
import {callAPI} from "../fuction";
import {
  setCabins,
  setBrands,
  setColors,
  setPassengerSeats,
  setSleepCabinTypes,
  setSleepCabinBeds,
  setAdditionalEquipment
} from "../store/dictionary";

function* getDictionaryData() {
  try {
    const response = yield call(callAPI, {
      url: '/api/v1/cabins',
      method: 'GET',
      data: {}
    })
    const data = yield response;
    yield put(setCabins(data.data))
  } catch (e) {
    console.log('Błąd pobierania danych')
  }

  try {
    const response = yield call(callAPI, {
      url: '/api/v1/brands',
      method: 'GET',
      data: {}
    })
    const data = yield response;
    yield put(setBrands(data.data))
  } catch (e) {
    console.log('Błąd pobierania danych')
  }

  try {
    const response = yield call(callAPI, {
      url: '/api/v1/colors',
      method: 'GET',
      data: {}
    })
    const data = yield response;
    yield put(setColors(data.data))
  } catch (e) {
    console.log('Błąd pobierania danych')
  }

  try {
    const response = yield call(callAPI, {
      url: '/api/v1/passenger-seats',
      method: 'GET',
      data: {}
    })
    const data = yield response;
    yield put(setPassengerSeats(data.data))
  } catch (e) {
    console.log('Błąd pobierania danych')
  }

  try {
    const response = yield call(callAPI, {
      url: '/api/v1/sleeping-cabin-types',
      method: 'GET',
      data: {}
    })
    const data = yield response;
    yield put(setSleepCabinTypes(data.data))
  } catch (e) {
    console.log('Błąd pobierania danych')
  }

  try {
    const response = yield call(callAPI, {
      url: '/api/v1/sleeping-cabin-beds',
      method: 'GET',
      data: {}
    })
    const data = yield response;
    yield put(setSleepCabinBeds(data.data))
  } catch (e) {
    console.log('Błąd pobierania danych')
  }

  try {
    const response = yield call(callAPI, {
      url: '/api/v1/additional-equipment',
      method: 'GET',
      data: {}
    })
    const data = yield response;
    yield put(setAdditionalEquipment(data.data))
  } catch (e) {
    console.log('Błąd pobierania danych')
  }

}

function* dictionarySaga() {
  yield takeEvery(sagaDictionaryActions.GET_DICTIONARY_DATA_SAGA, getDictionaryData)
}

export { dictionarySaga };
